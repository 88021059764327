import { Component, OnInit } from '@angular/core';
import { IClientDto } from 'clients-search-lib';

@Component({
  selector: 'howden-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
})
export class SearchButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  clientIsSelected(client: IClientDto) {
    alert(client ? client.name : 'no hay cliente');
  }
}
