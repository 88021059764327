import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

import { CLIENTS_SEARCH_CONSTANTS } from '../../../constants/clients-search.constants';
import { IClientDto } from '../../../models/client.dto';
import { IClientsFilterDto } from '../../../models/clients-filter.dto';
import { ClientsContract } from './clients.contract';

@Injectable({
  providedIn: 'root',
})
export class ClientsService implements ClientsContract {
  private apiName: string = CLIENTS_SEARCH_CONSTANTS.ClientIDAName;

  constructor(private howdenHttpClient: HowdenHttpClientService) {}

  searchClients(filter: IClientsFilterDto): Observable<IPageOf<IClientDto>> {
    const path = '/api/clients/detailed/search';
    return this.howdenHttpClient.post<IPageOf<IClientDto>>(this.apiName, path, filter);
  }
}
