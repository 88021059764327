import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

import { CLIENTS_SEARCH_CONSTANTS } from '../../../constants/clients-search.constants';
import { IOfficeDto } from '../../../models/office.dto';
import { OfficesContract } from './offices.contract';

@Injectable({
  providedIn: 'root',
})
export class OfficesService implements OfficesContract {
  private apiName: string = CLIENTS_SEARCH_CONSTANTS.CompanyIDAName;

  constructor(private howdenHttpClient: HowdenHttpClientService) {}
  get(): Observable<IOfficeDto[]> {
    const path = '/api/offices';
    return this.howdenHttpClient.get<IOfficeDto[]>(this.apiName, path);
  }
}
