<div class="mat-elevation-z2">
  <form [formGroup]="form">
    <div class="flex f-gap--5px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@clientsSearch.table.name">Nombre / Razón Social</mat-label>
        <input matInput [formControlName]="C_NAME" />
      </mat-form-field>

      <mat-form-field class="f-basis--1">
        <mat-label i18n="@@clientsSearch.table.taxId">NIF</mat-label>
        <input matInput [formControlName]="C_TAXID" />
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@clientsSearch.table.phone">Teléfono</mat-label>
        <input matInput [formControlName]="C_PHONE" />
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@clientsSearch.table.email">Email</mat-label>
        <input matInput [formControlName]="C_EMAIL" />
      </mat-form-field>

      <howden-autocomplete
        class="f-basis--2"
        label="Oficina"
        [selectableItems]="offices"
        displayField="name"
        [formControlName]="C_OFFICE"
      ></howden-autocomplete>
    </div>

    <div class="flex f-gap--5px">
      <howden-autocomplete
        class="f-basis--2"
        label="Ejecutivo"
        [selectableItems]="executives"
        displayField="name"
        [formControlName]="C_EXECUTIVE"
      ></howden-autocomplete>

      <howden-autocomplete
        class="f-basis--3"
        label="Profesión"
        [selectableItems]="professions"
        displayField="description"
        keyField="groupingCriteriaValueID"
        [formControlName]="C_PROFESSION"
      ></howden-autocomplete>

      <mat-form-field class="f-basis--2">
        <mat-label>Estado</mat-label>
        <mat-select [formControlName]="C_STATUS">
          <mat-option value="4" i18n="@@clientsSearch.table.clientsAndProspects">Clientes-Candidatos</mat-option>
          <mat-option value="3" i18n="@@clientsSearch.table.clients">Clientes</mat-option>
          <mat-option value="2" i18n="@@clientsSearch.table.exclients">Ex clientes</mat-option>
          <mat-option value="1" i18n="@@clientsSearch.table.candidates">Candidatos</mat-option>
          <mat-option value="" i18n="@@clientsSearch.table.all">Todos</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="f-basis--5 flex f-ai--center f-jc--end f-gap--10px">
        <button mat-raised-button color="primary" [disabled]="!searchIsEnable" (click)="search()">
          <mat-icon>search</mat-icon><span i18n="@@clientsSearch.table.search">Buscar</span>
        </button>

        <button mat-raised-button color="accent" (click)="clean()">
          <mat-icon>cleaning_services</mat-icon><span i18n="@@clientsSearch.table.clean">Limpiar</span>
        </button>

        @if (dialogRef) {
          <button mat-raised-button color="warn" (click)="close()">
            <mat-icon>close</mat-icon><span i18n="@@clientsSearch.table.close">Cerrar</span>
          </button>
        }
      </div>
    </div>
  </form>
</div>

<howden-table
  class="small-font"
  [ngClass]="{ none: !firstSearchDone }"
  [cols]="columns"
  [data]="data.data"
  [pageSize]="data.pageSize"
  [totalRows]="data.totalCount"
  [currentPage]="data.pageNumber"
  [pageSizeOptions]="[5, 10, 25, 50, 100]"
  [buttons]="buttons"
  paginable="server"
  sortable="server"
  [clickableRow]="true"
  (rowClicked)="viewClient($event)"
  (pageChanged)="eventSearch($event)"
  (sortChanged)="eventSearch($event)"
  (buttonClicked)="detailInfo($event)">
</howden-table>
