import { IClientsProfileRoutes } from '../models/gestion-clients-routes';

export const CLIENTS_SEARCH_CONSTANTS = {
  ClientIDAName: 'IDAClient',
  CompanyIDAName: 'IDACompany',
  MastersIDAName: 'IDAMasters',
  ClientsProfileRoutes: {
    local: 'https://how-intranetsingular.app-dev.howdeniberia.com/GestionClientes/Clientes/ficha',
    develop: 'https://how-intranetsingular.app-dev.howdeniberia.com/GestionClientes/Clientes/ficha',
    staging: 'https://how-intranetsingular.app-staging.howdeniberia.com/GestionClientes/Clientes/ficha',
    production: 'https://intranet.app.howdeniberia.com/GestionClientes/Clientes/ficha',
    devHowdenGroup: 'https://how-intranetsingular.app-dev.howdeniberia.com/GestionClientes/Clientes/ficha',
    preHowdenGroup: 'https://how-intranetsingular.app-staging.howdeniberia.com/GestionClientes/Clientes/ficha',
    proHowdenGroup: 'https://intranet.app.howdeniberia.com/GestionClientes/Clientes/ficha',
  } as IClientsProfileRoutes
};
