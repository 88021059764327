<div class="howden-dialog-content">
  <div mat-dialog-title>Información cliente</div>

  <mat-dialog-content>
    <div class="flex f-fd--column f-gap--10px">
      <div class="flex">
        <div class="f-basis--6">
          <howden-label-value labelText="Id:" [value]="client.id.toString()"></howden-label-value>
        </div>
        <div class="f-basis--6">
          <howden-label-value
            labelText="Nº pólizas vigor:"
            [value]="client.numActivePolicies?.toString()"></howden-label-value>
        </div>
      </div>
      <div class="flex">
        <div class="f-basis--6">
          <howden-label-value labelText="Tramitador siniestros:" [value]="client.claimsHandler"></howden-label-value>
        </div>
        <div class="f-basis--6">
          <howden-label-value labelText="Gestor ahorro:" [value]="client.savingsManagement"></howden-label-value>
        </div>
      </div>
      <div class="flex">
        <div class="f-basis--6">
          <howden-label-value labelText="NIF:" [value]="client.taxId"></howden-label-value>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex f-jc--center margin-top-25">
        <button mat-raised-button color="warn" (click)="close()"><mat-icon>close</mat-icon>Cerrar</button>
  </mat-dialog-actions>
</div>
