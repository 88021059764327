import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IClientDto } from './../../core/models/client.dto';

@Component({
  selector: 'howden-client-extra-info',
  templateUrl: './client-extra-info.component.html',
  styleUrls: ['./client-extra-info.component.scss'],
})
export class ClientExtraInfoComponent implements OnInit {
  client: IClientDto = {} as IClientDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IClientDto,
    public dialogRef: MatDialogRef<ClientExtraInfoComponent>
  ) {
    this.client = data;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
