import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ClientsSearchTableComponent } from '../clients-search-table/clients-search-table.component';
import { IClientDto } from './../../core/models/client.dto';

@Component({
  selector: 'howden-clients-search-button',
  templateUrl: './clients-search-button.component.html',
})
export class ClientsSearchButtonComponent implements OnInit {
  @Output()
  selectedClient = new EventEmitter<IClientDto>();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openClientsDialog() {
    this.dialog
      .open(ClientsSearchTableComponent, {
        maxWidth: '95%',
        maxHeight: '95%',
        height: '95%',
        width: '95%',
      })
      .afterClosed()
      .subscribe((result) => {
        this.selectedClient.emit(result);
      });
  }
}
