import { GENERAL_CONSTANTS, HowdenApiEnviroment, IHowdenApiInfo, IHowdenApiPaths } from '@howdeniberia/core-front';
import { CLIENTS_SEARCH_CONSTANTS } from 'clients-search-lib';

export const ApiConfigurations: IHowdenApiInfo[] = [
  {
    name: GENERAL_CONSTANTS.ApiSecurityName,
    paths: {
      local: 'https://localhost:7249',
      develop: 'https://how-apisecurity.app-dev.howdeniberia.com',
      staging: 'https://how-apisecurity.app-staging.howdeniberia.com',
      production: 'https://how-apisecurity.app.howdeniberia.com',
      devHowdenGroup: 'https://how-apisecurity.app-dev.howdeniberia.com',
      preHowdenGroup: 'https://how-apisecurity.app-staging.howdeniberia.com',
      proHowdenGroup: 'https://how-apisecurity.app.howdeniberia.com'
    },
    needsToken: true,
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE
  },
  {
    name: CLIENTS_SEARCH_CONSTANTS.ClientIDAName,
    paths: {
      local: 'https://localhost:9000',
      develop: 'https://how-ib-idaclients-dev.azurewebsites.net',
      staging: 'https://how-ib-idaclients-pre.azurewebsites.net',
      production: 'https://how-idaclients.app.howdeniberia.com',
      devHowdenGroup: 'https://how-ib-idaclients-dev.azurewebsites.net',
      preHowdenGroup: 'https://how-ib-idaclients-pre.azurewebsites.net',
      proHowdenGroup: 'https://how-idaclients.app.howdeniberia.com'
    } as IHowdenApiPaths,
    needsToken: true,
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE
  },
  {
    name: CLIENTS_SEARCH_CONSTANTS.CompanyIDAName,
    paths: {
      local: 'https://localhost:9001',
      develop: 'https://how-ib-idacompany-dev.azurewebsites.net',
      staging: 'https://how-ib-idacompany-pre.azurewebsites.net',
      production: 'https://how-idacompany.app.howdeniberia.com',
      devHowdenGroup: 'https://how-ib-idacompany-dev.azurewebsites.net',
      preHowdenGroup: 'https://how-ib-idacompany-pre.azurewebsites.net',
      proHowdenGroup: 'https://how-idacompany.app.howdeniberia.com'
    } as IHowdenApiPaths,
    needsToken: true,
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE
  },
  {
    name: CLIENTS_SEARCH_CONSTANTS.MastersIDAName,
    paths: {
      local: 'https://localhost:9002',
      develop: 'https://how-ib-idamaster-dev.azurewebsites.net',
      staging: 'https://how-ib-idamaster-pre.azurewebsites.net',
      production: 'https://how-idamaster.app.howdeniberia.com',
      devHowdenGroup: 'https://how-ib-idamaster-dev.azurewebsites.net',
      preHowdenGroup: 'https://how-ib-idamaster-pre.azurewebsites.net',
      proHowdenGroup: 'https://how-idamaster.app.howdeniberia.com'
    } as IHowdenApiPaths,
    needsToken: true,
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE
  },
] as IHowdenApiInfo[];