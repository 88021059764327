import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

import { CLIENTS_SEARCH_CONSTANTS } from '../../../constants/clients-search.constants';
import { IExecutiveDto } from '../../../models/executive.dto';
import { ExecutivesContract } from './executives.contract';

@Injectable({
  providedIn: 'root',
})
export class ExecutivesService implements ExecutivesContract {
  private apiName: string = CLIENTS_SEARCH_CONSTANTS.CompanyIDAName;

  constructor(private howdenHttpClient: HowdenHttpClientService) {}
  get(): Observable<IExecutiveDto[]> {
    const path = '/api/executives';
    return this.howdenHttpClient.get<IExecutiveDto[]>(this.apiName, path);
  }

  getExecutive(executiveID: number): Observable<IExecutiveDto> {
    const path = `/api/executives/${executiveID}`;
    return this.howdenHttpClient.get<IExecutiveDto>(this.apiName, path);
  }
}
