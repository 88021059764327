{
  "name": "clients-search-web",
  "title": "Buscador de Clientes",
  "version": "v2.18.4",
  "languages": [
    "es"
  ],
  "copyright": "Copyright 2015-2024 | Howden Iberia | Todos los derechos reservados",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:en": "ng serve -c=develop-en",
    "build": "ng build",
    "watch": "ng build --watch --configuration develop",
    "test": "ng test"
  },
  "private": true,
  "author": "howden iberia",
  "license": "ISC"
}
