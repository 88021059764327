import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

import { CLIENTS_SEARCH_CONSTANTS } from '../../../constants/clients-search.constants';
import { IUserDto } from '../../../models/user.dto';
import { UsersContract } from './users.contract';

@Injectable({
  providedIn: 'root',
})
export class UsersService implements UsersContract {
  private apiName: string = CLIENTS_SEARCH_CONSTANTS.CompanyIDAName;

  constructor(private howdenHttpClient: HowdenHttpClientService) {}

  get(userID: string): Observable<IUserDto> {
    const path = `/api/users/${userID}`;
    return this.howdenHttpClient.get<IUserDto>(this.apiName, path);
  }
}
