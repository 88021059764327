import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

import { CLIENTS_SEARCH_CONSTANTS } from '../../../constants/clients-search.constants';
import { IGroupingCriteriaValueDTO } from '../../../models/grouping-criteria-value.dto';
import { ProfessionsContract } from './professions.contract';

@Injectable({
  providedIn: 'root',
})
export class ProfessionsService implements ProfessionsContract {
  private apiName: string = CLIENTS_SEARCH_CONSTANTS.MastersIDAName;

  constructor(private howdenHttpClient: HowdenHttpClientService) {}

  get(): Observable<IGroupingCriteriaValueDTO[]> {
    return this.howdenHttpClient.get<IGroupingCriteriaValueDTO[]>(this.apiName, 'api/grouping-criteria-values/1');
  }
}
