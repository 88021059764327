/*
 * Public API Surface of clients-search-lib
 */
export { ClientsSearchButtonComponent } from './lib/components/clients-search-button/clients-search-button.component';
export { ClientsSearchTableComponent } from './lib/components/clients-search-table/clients-search-table.component';
export { ClientsSearchLibModule } from './lib/clients-search-lib.module';
export { IClientDto } from './lib/core/models/client.dto';
export { ClientsSearchApiInfo as SearchClientsApiInfo } from './lib/core/services/api/clients-search-api-Info';
export { CLIENTS_SEARCH_CONSTANTS } from './lib/core/constants/clients-search.constants';
export { IClientsProfileRoutes as IGestionClientesRoutes } from './lib/core/models/gestion-clients-routes';
