import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  GENERAL_CONSTANTS,
  HowdenAutocompleteModule,
  HowdenLabelValueModule,
  HowdenSharedModule,
  HowdenTableModule,
  IHowdenApiInfo,
} from '@howdeniberia/core-front';

import { ClientExtraInfoComponent } from './components/client-extra-info/client-extra-info.component';
import { ClientsSearchButtonComponent } from './components/clients-search-button/clients-search-button.component';
import { ClientsSearchTableComponent } from './components/clients-search-table/clients-search-table.component';
import { CLIENTS_SEARCH_CONSTANTS } from './core/constants/clients-search.constants';
import { IClientsProfileRoutes } from './core/models/gestion-clients-routes';
import { ClientsSearchApiInfo } from './core/services/api/clients-search-api-Info';
import { ClientsContract } from './core/services/api/ida-clients/clients.contract';
import { ClientsService } from './core/services/api/ida-clients/clients.service';
import { ExecutivesContract } from './core/services/api/ida-company/executives.contract';
import { ExecutivesService } from './core/services/api/ida-company/executives.service';
import { OfficesContract } from './core/services/api/ida-company/offices.contract';
import { OfficesService } from './core/services/api/ida-company/offices.service';
import { UsersContract } from './core/services/api/ida-company/users.contract';
import { UsersService } from './core/services/api/ida-company/users.service';
import { ProfessionsContract } from './core/services/api/ida-masters/professions.contract';
import { ProfessionsService } from './core/services/api/ida-masters/professions.service';

@NgModule({
  declarations: [ClientsSearchTableComponent, ClientsSearchButtonComponent, ClientExtraInfoComponent],
  imports: [
    CommonModule,
    MatCommonModule,
    HowdenTableModule,
    HowdenLabelValueModule,
    HowdenSharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    HowdenAutocompleteModule,
  ],
  exports: [ClientsSearchButtonComponent, ClientsSearchTableComponent],
})
export class ClientsSearchLibModule {
  public static forRoot(
    apiInfo: IHowdenApiInfo[] | undefined = undefined,
    urlsGestionClientes: IClientsProfileRoutes | undefined = undefined
  ): ModuleWithProviders<ClientsSearchLibModule> {
    ClientsSearchLibModule.mergeClientsProfileRoutes(urlsGestionClientes);

    const moduleProviders = [
      {
        provide: GENERAL_CONSTANTS.HowdenApiInfoProvideName,
        useValue: ClientsSearchLibModule.mergeClientsSearchApiInfo(apiInfo),
      },
      {
        provide: ClientsContract,
        useClass: ClientsService,
      },
      {
        provide: ExecutivesContract,
        useClass: ExecutivesService,
      },
      {
        provide: OfficesContract,
        useClass: OfficesService,
      },
      {
        provide: UsersContract,
        useClass: UsersService,
      },
      {
        provide: ProfessionsContract,
        useClass: ProfessionsService,
      },
    ];
    return {
      ngModule: ClientsSearchLibModule,
      providers: moduleProviders,
    };
  }

  public static mergeClientsSearchApiInfo(howdenApiInfo: IHowdenApiInfo[] | undefined): IHowdenApiInfo[] {
    const result: IHowdenApiInfo[] = [];
    if (howdenApiInfo && howdenApiInfo.length > 0) {
      howdenApiInfo.forEach((value) => {
        result.push(value);
      });
    }

    ClientsSearchApiInfo.forEach((value: IHowdenApiInfo) => {
      let exists = result.find((x) => x.name === value.name);
      if (exists) {
        exists = value;
      }
      result.push(value);
    });
    return result;
  }

  public static mergeClientsProfileRoutes(urlsGestionClientes: IClientsProfileRoutes | undefined) {
    if (urlsGestionClientes) {
      if (urlsGestionClientes.local) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.local = urlsGestionClientes.local;
      }

      if (urlsGestionClientes.develop) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.develop = urlsGestionClientes.develop;
      }

      if (urlsGestionClientes.staging) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.staging = urlsGestionClientes.staging;
      }

      if (urlsGestionClientes.production) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.production = urlsGestionClientes.production;
      }

      if (urlsGestionClientes.devHowdenGroup) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.devHowdenGroup = urlsGestionClientes.devHowdenGroup;
      }

      if (urlsGestionClientes.preHowdenGroup) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.preHowdenGroup = urlsGestionClientes.preHowdenGroup;
      }

      if (urlsGestionClientes.proHowdenGroup) {
        CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.proHowdenGroup = urlsGestionClientes.proHowdenGroup;
      }
    }
  }
}
