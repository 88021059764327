import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SearchButtonComponent } from './pages/search-button/search-button.component';
import { SearchClientsComponent } from './pages/search-clients/search-clients.component';

export const ElixyrRoutes = [
  {
    path: '',
    component: SearchClientsComponent,
    title: $localize`:@@app.route.search-clients:Buscador`,
  },
  {
    path: 'boton',
    component: SearchButtonComponent,
    title: $localize`:@@app.route.search-clients:Botón clientes`,
  },
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ElixyrRoutes)],
  exports: [RouterModule],
})
export class SearchClientsRoutingModule {}
