import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsSearchLibModule } from 'clients-search-lib';

import { SearchButtonComponent } from './pages/search-button/search-button.component';
import { SearchClientsComponent } from './pages/search-clients/search-clients.component';
import { SearchClientsRoutingModule } from './search-clients-routing.module';

@NgModule({
  declarations: [SearchClientsComponent, SearchButtonComponent],
  imports: [CommonModule, SearchClientsRoutingModule, ClientsSearchLibModule.forRoot()],
  exports: [SearchClientsRoutingModule],
  providers: [
    { provide: MatDialogRef, useValue: undefined },
    { provide: MAT_DIALOG_DATA, useValue: undefined },
  ],
})
export class SearchClientsModule {}
