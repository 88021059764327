import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ColumnPipeTypes,
  EnviromentZone,
  GENERAL_CONSTANTS,
  HowdenAlertService,
  HowdenAuthService,
  IButtonActionData,
  IHowdenColumnConfig,
  IHowdenEnvironment,
  IPageOf,
  ITableButtonConfig,
  ITableEventData,
  TableButtonColors,
} from '@howdeniberia/core-front';
import { forkJoin } from 'rxjs';

import { ExecutiveStatus } from '../../core/enums/executive-status.enum';
import { IClientDto } from '../../core/models/client.dto';
import { IGroupingCriteriaValueDTO } from '../../core/models/grouping-criteria-value.dto';
import { ClientsContract } from '../../core/services/api/ida-clients/clients.contract';
import { ExecutivesContract } from '../../core/services/api/ida-company/executives.contract';
import { OfficesContract } from '../../core/services/api/ida-company/offices.contract';
import { UsersContract } from '../../core/services/api/ida-company/users.contract';
import { ProfessionsContract } from '../../core/services/api/ida-masters/professions.contract';
import { CLIENTS_SEARCH_CONSTANTS } from './../../core/constants/clients-search.constants';
import { IClientsFilterDto } from './../../core/models/clients-filter.dto';
import { IExecutiveDto } from './../../core/models/executive.dto';
import { IOfficeDto } from './../../core/models/office.dto';
import { ClientExtraInfoComponent } from './../client-extra-info/client-extra-info.component';

@Component({
  selector: 'howden-clients-search-table',
  templateUrl: './clients-search-table.component.html',
})
export class ClientsSearchTableComponent implements OnInit {
  private readonly DEFAULT_DATA = {
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  } as IPageOf<IClientDto>;
  private _currentExecutive?: IExecutiveDto;
  private _callback?: string;
  offices: IOfficeDto[] = [];
  executives: IExecutiveDto[] = [];
  professions: IGroupingCriteriaValueDTO[] = [];

  form: FormGroup;
  C_NAME = 'Name';
  C_TAXID = 'TaxId';
  C_PHONE = 'Phone';
  C_MOBILE = 'Mobile';
  C_EMAIL = 'Email';
  C_STATUS = 'Status';
  C_OFFICE = 'Office';
  C_EXECUTIVE = 'Executive';
  C_PROFESSION = 'Profession';

  data: IPageOf<IClientDto> = this.DEFAULT_DATA;

  columns: IHowdenColumnConfig[] = [
    {
      fieldName: 'noDues',
      fieldHeader: 'Impagos',
      canSort: true,
      pipeToApply: ColumnPipeTypes.CHECK,
      width: '4%',
    },
    {
      fieldName: 'alias',
      fieldHeader: 'Alias',
      canSort: true,
      width: '5%',
    },
    {
      fieldName: 'fullName',
      fieldHeader: 'Nombre',
      canSort: true,
    },
    {
      fieldName: 'status',
      fieldHeader: 'Estado',
      width: '5%',
    },
    {
      fieldName: 'creditAccount',
      fieldHeader: 'Cta. Crédito',
      canSort: true,
      pipeToApply: ColumnPipeTypes.CHECK,
      width: '4%',
    },
    {
      fieldName: 'mobile',
      fieldHeader: 'Teléfono',
      canSort: true,
      width: '10%',
    },
    {
      fieldName: 'email',
      fieldHeader: 'Email',
      canSort: true,
      width: '12%',
    },
    {
      fieldName: 'office',
      fieldHeader: 'Oficina',
      canSort: true,
      width: '5%',
    },
    {
      fieldName: 'salesExecutive',
      fieldHeader: 'Ejecutivo',
      canSort: true,
      width: '10%',
    },
    {
      fieldName: 'birth',
      fieldHeader: 'F. Nacimiento',
      pipeToApply: ColumnPipeTypes.DATE,
      canSort: true,
      width: '6%',
    },
    {
      fieldName: 'profession',
      fieldHeader: 'Profesión',
      canSort: true,
      width: '12%',
    },
  ];

  buttons: ITableButtonConfig[] = [
    {
      name: 'extra-info',
      icon: 'info',
      tooltip: 'Información Adicional',
      color: TableButtonColors.INFO,
    },
  ];

  firstSearchDone = false;

  get searchIsEnable(): boolean {
    return (
      ((this.form.get(this.C_NAME)?.value && this.form.get(this.C_NAME)?.value.length > 2) ||
        (this.form.get(this.C_TAXID)?.value && this.form.get(this.C_TAXID)?.value.length > 2) ||
        (this.form.get(this.C_PHONE)?.value && this.form.get(this.C_PHONE)?.value.length > 2) ||
        (this.form.get(this.C_MOBILE)?.value && this.form.get(this.C_MOBILE)?.value.length > 2) ||
        (this.form.get(this.C_EMAIL)?.value && this.form.get(this.C_EMAIL)?.value.length > 2) ||
        this.form.get(this.C_PROFESSION)?.value ||
        this.form.get(this.C_OFFICE)?.value ||
        this.form.get(this.C_EXECUTIVE)?.value) &&
      this.form.valid
    );
  }

  constructor(
    private clientsSrv: ClientsContract,
    private executivesSrv: ExecutivesContract,
    private officesSrv: OfficesContract,
    private usersSrv: UsersContract,
    private authSrv: HowdenAuthService,
    @Inject(GENERAL_CONSTANTS.EnvironmentProvideName) private howdenEnviroment: IHowdenEnvironment,
    public dialogRef: MatDialogRef<ClientsSearchTableComponent>,
    private dialog: MatDialog,
    private alertSrv: HowdenAlertService,
    private professionsSrv: ProfessionsContract,
    private route: ActivatedRoute
  ) {
    this.form = new FormGroup({
      [this.C_NAME]: new FormControl('', []),
      [this.C_TAXID]: new FormControl('', []),
      [this.C_PHONE]: new FormControl('', []),
      [this.C_MOBILE]: new FormControl('', []),
      [this.C_EMAIL]: new FormControl('', []),
      [this.C_STATUS]: new FormControl('4', []),
      [this.C_OFFICE]: new FormControl(null, []),
      [this.C_EXECUTIVE]: new FormControl(null, []),
      [this.C_PROFESSION]: new FormControl(null, []),
    });
  }

  ngOnInit(): void {
    if (!this.authSrv.isLogued()) {
      return;
    }

    const company = this.authSrv.authData?.accessCompanies?.find(
      (p) => p.companyDataSource == this.howdenEnviroment.fixedTenant
    );

    forkJoin({
      executives: this.executivesSrv.get(),
      offices: this.officesSrv.get(),
      connectedUser: this.usersSrv.get(company?.dataSourceUserID ?? ''),
      professions: this.professionsSrv.get(),
    }).subscribe((result) => {
      this.offices = result.offices
        .filter((p) => p.name)
        .sort((n1, n2) => {
          if (n1.name > n2.name) {
            return 1;
          }

          if (n1.name < n2.name) {
            return -1;
          }

          return 0;
        });

      this.executives = result.executives
        .filter((p) => p.status == ExecutiveStatus.ACTIVE)
        .sort((n1, n2) => {
          if (n1.name > n2.name) {
            return 1;
          }

          if (n1.name < n2.name) {
            return -1;
          }

          return 0;
        });

      if (!this.dialogRef) {
        this.route.queryParams.subscribe((p) => {
          if (p['callback']) {
            this._callback = p['callback'];
          }
        });
      }

      if (this.authSrv.userHasRol('ClientsOwned')) {
        this._currentExecutive = result.executives.find((e) => e.executiveId == result.connectedUser.executiveId);
      }

      this.professions = result.professions;
    });
  }

  viewClient(row: IClientDto) {
    if (this._currentExecutive && row.salesExecutiveId != this._currentExecutive.executiveId) {
      this.alertSrv.warning(
        $localize`:@@clientsSearch.table.warning: Aviso`,
        $localize`:@@clientsSearch.table.accessErrorMessage: Solo puedes acceder a los clientes en los que estés asignado como ejecutivo`
      );
      return;
    }

    if (this.dialogRef) {
      this.dialogRef.close(row);
    } else {
      let baseUrl = this._callback;

      if (!baseUrl) {
        switch (this.howdenEnviroment.zone) {
          case EnviromentZone.LOCAL:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.local ?? '';
            break;
          case EnviromentZone.DEVELOP:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.develop ?? '';
            break;
          case EnviromentZone.STAGING:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.staging ?? '';
            break;
          case EnviromentZone.PRODUCTION:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.production ?? '';
            break;
         case EnviromentZone.DEVHOWDENGROUP:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.devHowdenGroup ?? '';
            break;
          case EnviromentZone.PREHOWDENGROUP:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.preHowdenGroup ?? '';
            break;
          case EnviromentZone.PROHOWDENGROUP:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.proHowdenGroup ?? '';
            break;
          default:
            baseUrl = CLIENTS_SEARCH_CONSTANTS.ClientsProfileRoutes.local ?? ''; //Nunca puede ser null
            break;
        }
      }

      if (baseUrl) {
        window.open(
          `${baseUrl}?CLI_Id=${row.id}`,
          '_blank' // <- This is what makes it open in a new window.
        );
      }

      return;
    }
  }

  search() {
    if (!this.firstSearchDone) {
      this.firstSearchDone = true;
    }

    this.eventSearch();
  }
  clean() {
    this.data = this.DEFAULT_DATA;
    this.form.reset();
    this.form.get(this.C_STATUS)?.setValue('1');
    //this.eventSearch();
  }

  close() {
    this.dialogRef.close();
  }

  eventSearch(event: ITableEventData | null = null) {
    const pageSize = event ? event.pageSize : this.data.pageSize;
    const pageIndex = event ? event.pageIndex : 0;
    const sort = event ? event.order : null;
    const fieldSort = event ? event.fieldSorted : null;

    this.clientsSrv
      .searchClients({
        pageSize: pageSize,
        pageNumber: pageIndex,
        sortDirection: sort,
        sortedBy: fieldSort,
        mobile: this.form.get(this.C_MOBILE)?.value,
        status: this.form.get(this.C_STATUS)?.value ? Number(this.form.get(this.C_STATUS)?.value) : null,
        email: this.form.get(this.C_EMAIL)?.value,
        name: this.form.get(this.C_NAME)?.value,
        phone: this.form.get(this.C_PHONE)?.value,
        taxID: this.form.get(this.C_TAXID)?.value,
        officeId: this.form.get(this.C_OFFICE)?.value?.officeId,
        salesExecutiveId: this.form.get(this.C_EXECUTIVE)?.value?.executiveId,
        professionID: this.form.get(this.C_PROFESSION)?.value?.toString(),
      } as IClientsFilterDto)
      .subscribe({
        next: (value: IPageOf<IClientDto>) => {
          value.data.forEach((client: IClientDto) => {
            if (!client.mobile) {
              client.mobile = client.phone;
            } else if (client.mobile && client.phone) {
              client.mobile = `${client.mobile} / ${client.phone}`;
            }
          });

          this.data = value;
        },
      });
  }

  detailInfo(eventData: IButtonActionData) {
    this.dialog.open(ClientExtraInfoComponent, {
      data: eventData.row,
      width: '500px',
    });

    return false;
  }
}
