import '@angular/common/locales/global/es';
import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  getPaginatorIntl,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenWithoutMenuLayoutModule,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
} from '@howdeniberia/core-front';
import "moment/locale/es";
import packageJson from '../../package.json';
import { ApiConfigurations } from '../environments/api-configurations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchClientsModule } from './features/search-clients/search-clients.module';

export const AppProviders: Provider[] = [
  NotificationsService,
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'fill',
    },
  },
  { provide: LOCALE_ID, useValue: 'es' },
  { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HowdenWithoutMenuLayoutModule,
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    SearchClientsModule,
    MatDialogModule,
    HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations),
  ],
  providers: AppProviders,
  bootstrap: [AppComponent],
})
export class AppModule {}
